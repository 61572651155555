/* eslint-disable object-shorthand */

import { makeAutoObservable } from 'mobx';

export default class {
  attributes = {};
  authToken = '';
  avatarUrl = '';
  campaignHeaderPhotoUrl = '';
  campaignTemplateId = '';
  campaignUrl = '';
  costPerPerson = '';
  country = '';
  currentUse = '';
  cwPersonalizedPhotoUrl = '';
  defaultCampaignPhotos = {};
  defaultCwPersonalizedPhotos = {};
  defaultGoalAmount = 600;
  donors = [];
  endAt = this.defaultEndAt;
  endAtForNewCampaign = '';
  initialRedirectToCampaign = false;
  invitesCount = 0;
  maxAmount = 0;
  missionStatementContentfulContent = '';
  openGoalModal = false;
  showDrawer = true;
  steps = [];
  stepName = this.steps[0];
  team = {};
  teamAvatarUrl = '';
  teamId = '';
  teamSlug = '';
  user = {};
  userId = '';
  userName = '';
  userSlug = '';

  constructor() {
    makeAutoObservable(this);
  }

  get goalAmount() {
    if (this.attributes == undefined) {
      return;
    }
    return this.attributes['goalAmount'];
  }

  get goalAmountIsNull() {
    return this.goalAmount == null;
  }

  get isTeamCampaign() {
    return !!this.teamId;
  }

  get currencyUnit() {
    return {
      us: '$',
      uk: '£',
    }[this.attributes?.country || this.country];
  }

  get currencyName() {
    return {
      us: 'usd',
      uk: 'gbp',
    }[this.attributes?.country || this.country];
  }

  get defaultEndAt() {
    return new Date(this.endAtForNewCampaign);
  }

  get stepNumber() {
    return this.steps.findIndex((stepName) => stepName == this.stepName);
  }

  get nextStepName() {
    return this.steps[this.stepNumber + 1];
  }

  get nextStepPath() {
    return `../${this.nextStepName}`;
  }

  setStepName = (stepName) => {
    this.stepName = stepName;
  };

  setCampaignAndUserAttributes = (data) => {
    this.attributes = data.campaign;
    this.avatarUrl = data.campaign.avatarUrl;
    this.campaignHeaderPhotoUrl = data.campaign.campaignHeaderPhotoUrl;
    this.defaultCampaignPhotos = data.campaign.defaultCampaignPhotos;
    this.cwPersonalizedPhotoUrl = data.campaign.cwPersonalizedPhotoUrl;
    this.defaultCwPersonalizedPhotos =
      data.campaign.defaultCwPersonalizedPhotos;
    this.userSlug = data.user_slug;
    this.teamId = data.campaign.teamId;
    this.teamSlug = data.campaign.teamSlug;
  };

  updateCampaign = (
    data,
    successCallback = () => {},
    failureCallback = () => {}
  ) => {
    $.ajax({
      method: 'PUT',
      url: `/campaigns/${this.attributes.id}`,
      beforeSend(xhr, options) {
        options.data = data;
        return true;
      },
      success: (data) => {
        this.setCampaignAndUserAttributes(data);
        successCallback(data);
      },
      error: (data) => {
        failureCallback(data.responseJSON);
      },
    });
  };

  updateUser = (data, successCallback = () => {}) => {
    $.ajax({
      method: 'PUT',
      url: '/account/settings',
      beforeSend(xhr, options) {
        options.data = data;
        return true;
      },
      success: (data) => {
        successCallback(data);
      },
    });
  };

  createCampaign = (data, navigate = () => {}, failureCallback = () => {}) => {
    $.ajax({
      method: 'POST',
      url: '/campaigns',
      data: data,
      dataType: 'json',
      success: (data) => {
        this.setCampaignAndUserAttributes(data);
        if (data.redirect_to) {
          navigate(data.redirect_to);
        }
      },
      error: (data) => {
        if (failureCallback) {
          failureCallback(data.responseJSON);
        }
        if (data.responseJSON.redirect_to) {
          navigate(data.responseJSON.redirect_to);
          window.location.href = data.responseJSON.redirect_to;
        }
      },
    });
  };
}
