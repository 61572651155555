import { makeAutoObservable } from 'mobx';

export default class {
  autoplay = false;
  clips = [];
  currentVideo = null;
  episodes = [];
  showNextVideoCta = false;

  constructor() {
    makeAutoObservable(this);
  }

  get nextVideo() {
    if (this.currentVideo === 'undefined') return null;

    const index = this.videos.findIndex(
      (video) => video.video_id == this.currentVideo.video_id
    );
    return this.videos[index + 1];
  }

  get videos() {
    return [this.clips[0], ...this.episodes, ...this.clips.slice(1)].filter(
      (e) => typeof e !== 'undefined'
    );
  }

  setCurrentVideo = (video) => {
    this.currentVideo = video;
    this.currentVideo25PercentPlayTracked = false;
    this.currentVideo50PercentPlayTracked = false;
    this.currentVideo90PercentPlayTracked = false;
  };
}
