/* eslint-disable object-shorthand */

import { makeAutoObservable, remove } from 'mobx';

export default class {
  brandPartner;
  id = '';
  formattedLifetimeImpactAmount = '';
  members = {};
  memberCount = 0;
  membersRemoved = false;
  name = '';
  pendingInvitationsCount = 0;
  peopleServed = 0;
  peopleServedOverride = '';
  refreshLeaderboard = false;
  selectedMember = {};
  slug = '';

  constructor() {
    makeAutoObservable(this);
  }

  get peopleServedOverrideOrActual() {
    return (
      (this.brandPartner && this.peopleServedOverride) || this.peopleServed
    );
  }

  toggleSelectedMember = (slug) => {
    this.selectedMember = this.members[slug];
  };

  updateAdminPermissions = () => {
    $.ajax({
      method: 'PUT',
      url: `/teams/${this.slug}/teams_members/${this.selectedMember.teamMemberId}`,
      data: {
        admin: !this.selectedMember.isTeamAdmin,
      },
      dataType: 'json',
      success: (data) => {
        const updatedMemberData = Object.assign({}, this.selectedMember, data);
        this.members[data.slug] = updatedMemberData;
        this.selectedMember = this.members[data.slug];
      },
    });
  };

  removeFromTeam = () => {
    $.ajax({
      method: 'DELETE',
      url: `/teams/${this.slug}/teams_members/${this.selectedMember.teamMemberId}`,
      dataType: 'json',
      success: (data) => {
        remove(this.members, data.slug);
        this.membersRemoved = true;
        this.memberCount -= 1;
        this.selectedMember = this.members[Object.keys(this.members)[0]];
        if (data.updatedTeamLifetimeImpact) {
          this.formattedLifetimeImpactAmount = data.updatedTeamLifetimeImpact;
          this.peopleServed = data.updatedTeamPeopleServed;
        }
      },
    });
  };

  createTeam = (
    data,
    navigate = () => {},
    successCallback = () => {},
    failureCallback = () => {}
  ) => {
    $.ajax({
      method: 'POST',
      url: '/teams',
      beforeSend(xhr, options) {
        options.data = data;
        return true;
      },
      success: (data) => {
        successCallback(data);

        if (data.redirect_to) {
          navigate(data.redirect_to);
        }
      },
      error: (data) => {
        failureCallback(data.responseJSON);
      },
    });
  };
}
