import { makeAutoObservable } from 'mobx';

export default class {
  offCanvasOpen = false;
  offCanvasActiveSection = '';
  showNewNav = false;

  constructor() {
    makeAutoObservable(this);
  }

  toggleOffCanvas = () => {
    this.offCanvasOpen = !this.offCanvasOpen;

    if (this.offCanvasOpen) {
      $('body').addClass('off-canvas-open');
      $('body').removeClass('off-canvas-animate-then-close');
      $('.offcanvas-open-link').removeClass('cw-icon-reorder-thin');
      $('.offcanvas-open-link').addClass('cw-icon-angle-close');
    } else {
      $('body').addClass('off-canvas-animate-then-close');
      $('body').removeClass('off-canvas-open');
      $('.offcanvas-open-link').addClass('cw-icon-reorder-thin');
      $('.offcanvas-open-link').removeClass('cw-icon-angle-close');

      setTimeout(() => {
        $('body').removeClass('off-canvas-animate-then-close');
      }, 500);
    }
  };

  toggleOffCanvasAccountSection = () => {
    this.toggleOffCanvas();

    if (this.offCanvasOpen) {
      this.offCanvasActiveSection = 'account';
    } else {
      this.offCanvasActiveSection = '';
    }
  };
}
