import { get, has, template, templateSettings } from 'lodash';

export default (key, data) => {
  templateSettings.interpolate = /%{([\s\S]+?)}/g;

  const translationLocale = get(translations[locale], key) ? locale : 'en';

  if (!has(translations[translationLocale], key))
    throw `Missing translation: ${key}, check that the key is present in config/locales/js.yml`;

  const compiled = template(get(translations[translationLocale], key), data);

  return compiled(data);
};
