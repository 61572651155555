import { camelCase } from 'lodash';

const modules = import.meta.glob('~/javascripts/stores/*.jsx', {
  eager: true,
});

class Store {
  constructor() {
    Object.keys(modules).forEach((module) => {
      this[camelCase(module.split('/').pop().replace('.jsx', ''))] =
        new modules[module].default();
    });
  }
}

const store = new Store();

export { Store };
export default store;
