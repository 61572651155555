import Cookies from 'js-cookie';
import queryString from 'query-string';
import { isEmpty, omitBy, pickBy, startsWith } from 'lodash';

function parsedQueryString() {
  return queryString.parse(window.location.search);
}

function filterInvalidParams(params) {
  return omitBy(params, (_v, k) => k === '');
}

function utmParams() {
  return filterInvalidParams(
    pickBy(parsedQueryString(), (_v, k) => startsWith(k, 'utm_'))
  );
}

function nonUtmParams() {
  return filterInvalidParams(
    omitBy(parsedQueryString(), (_v, k) => startsWith(k, 'utm_'))
  );
}

function touchTypeParam() {
  return { touch_type: isEmpty(utmParams()) ? 1 : 'immediate' };
}

function utmVisitedTimeParam() {
  const utmVisitedTime = new Date().toISOString();
  return {
    utm_visited_time: utmVisitedTime,
  };
}

function utmParamsOrCookieValues(cookieName) {
  if (isEmpty(utmParams())) {
    return filterInvalidParams(Cookies.getJSON(cookieName));
  } else {
    return utmParams();
  }
}

function utmParamsOrUtm30DayCookieValues() {
  return utmParamsOrCookieValues('maji_utm');
}

function utmParamsOrUtmSessionCookieValues() {
  return utmParamsOrCookieValues('utm_session');
}

function urlParamsMetadataFields() {
  return {
    ...utmParamsOrUtm30DayCookieValues(),
    ...nonUtmParams(),
    ...touchTypeParam(),
  };
}

function sessionUrlParamsMetadataFields() {
  return {
    ...utmParamsOrUtmSessionCookieValues(),
    ...nonUtmParams(),
    ...touchTypeParam(),
  };
}

export {
  parsedQueryString,
  urlParamsMetadataFields,
  sessionUrlParamsMetadataFields,
  touchTypeParam,
  utmParams,
  utmParamsOrUtm30DayCookieValues,
  utmParamsOrUtmSessionCookieValues,
  utmVisitedTimeParam,
};
