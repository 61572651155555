import t from '~/javascripts/utils/translation';
import { cloneDeep } from 'lodash';
import { format, parse } from 'date-fns';
import { makeAutoObservable } from 'mobx';

export default class {
  defaultHonoree = {
    amountHidden: false,
    deliveryNotification: false,
    fromFullName: '',
    honoreeFullName: '',
    message: '',
    recipientEmail: '',
    recipientFullName: '',
    sendOn: format(new Date(), t('views.donate_form.iho.date_parse_format')),
    sendTo: 'honoree',
    sendType: 'now',
    type: 'email',
  };

  constructor() {
    makeAutoObservable(this);
  }

  get donationFormParams() {
    const params = {
      amount_hidden: this.honoree.amountHidden,
      delivery_notification: this.honoree.deliveryNotification,
      design: this.design.slug,
      from_full_name: this.honoree.fromFullName,
      honoree_full_name: this.honoree.honoreeFullName,
      message: this.honoree.message,
      recipient_email: this.honoree.recipientEmail,
      recipient_full_name:
        this.honoree.sendTo === 'honoree'
          ? this.honoree.honoreeFullName
          : this.honoree.recipientFullName,
      send_to: this.honoree.sendTo,
      type: this.honoree.type,
    };

    if (this.honoree.sendType === 'schedule') {
      params['send_on'] = format(
        parse(
          this.honoree.sendOn,
          t('views.donate_form.iho.date_parse_format'),
          new Date()
        ),
        'yyyy-MM-dd'
      );
    }

    return params;
  }

  get steps() {
    return {
      email: ['type', 'details', 'delivery', 'design', 'preview'],
      print: ['type', 'details', 'delivery', 'preview'],
    }[this.honoreeDraft.type];
  }

  get isFirstStep() {
    return this.step === this.steps[0];
  }

  get isLastStep() {
    return this.step === this.steps[this.steps.length - 1];
  }

  chooseDesignMode = false;
  design = {};
  designs = [];
  honoree = this.defaultHonoree;
  honoreeDraft = this.defaultHonoree;
  honoreeInfoIsPresent = false;
  modalOpen = false;
  previewLoading;
  sendToHonoreeLabel = 'honoree';
  step = this.steps[0];
  tab = 'form';

  saveHonoree = () => {
    this.honoree =
      this.honoreeDraft['type'] === 'print'
        ? { ...this.honoreeDraft, recipientEmail: '' }
        : cloneDeep(this.honoreeDraft);
    this.honoreeInfoIsPresent = true;
  };

  removeHonoree = () => {
    this.honoree = this.defaultHonoree;
    this.honoreeDraft = this.defaultHonoree;
    this.honoreeInfoIsPresent = false;
    this.step = this.steps[0];
  };

  updateHonoreeDraft = (event) => {
    const { target } = event;

    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.honoreeDraft[target.name] = value;
  };

  handleNextStep = () => {
    this.step = this.steps[this.steps.indexOf(this.step) + 1];
  };

  handlePreviousStep = () => {
    this.step = this.steps[this.steps.indexOf(this.step) - 1];
  };

  setDesigns = (designs) => {
    this.designs = designs;
    this.design = designs[0];
  };

  handleSendToChange = (value) => {
    this.honoreeDraft.sendTo = value;
  };
}
