import { makeAutoObservable } from 'mobx';

export default class {
  campaigns = [];
  defaultTrendingCampaign = {};
  defaultNewCampaign = {};
  defaultSpringMembersCampaign = {};
  isSubmitting = false;
  overriddenTrendingCampaignId = '';
  page = 1;
  parentCampaignId = '';
  searchInput = '';
  selectedPill = '';
  totalPages = 0;

  constructor() {
    makeAutoObservable(this);
  }

  fetchCampaigns = () => {
    this.isSubmitting = true;

    $.get(
      `/api/v1/campaign_search?search=${this.searchInput}&filter=${this.selectedPill}&page=${this.page}&overridden_trending_campaign_id=${this.overriddenTrendingCampaignId}`,
      (response) => {
        if (response.isDefaultCampaignResults) {
          this.defaultTrendingCampaign = response.campaigns.find(
            (campaign) => campaign.filter === 'TRENDING'
          );
          this.defaultSpringMembersCampaign = response.campaigns.find(
            (campaign) => campaign.filter === 'SPRING_MEMBERS'
          );
          this.defaultNewCampaign = response.campaigns.find(
            (campaign) => campaign.filter === 'NEW'
          );
        } else {
          this.campaigns = response.campaigns;
          this.totalPages = response.totalPages;
        }

        this.isSubmitting = false;
      }
    );
  };

  fetchChildCampaigns = () => {
    this.isSubmitting = true;

    $.get(
      `/api/v1/parent_campaign_search?search=${this.searchInput}&page=${this.page}&parent_campaign_id=${this.parentCampaignId}`,
      (response) => {
        this.campaigns = response.campaigns;
        this.totalPages = response.totalPages;
        this.isSubmitting = false;
      }
    );
  };
}
