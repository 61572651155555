import { makeAutoObservable } from 'mobx';

export default class {
  currencyName = '';
  currencyUnit = '';
  profile = {};
  globalTotalPeopleServed = '';
  userTotalPeopleServed = '';

  constructor() {
    makeAutoObservable(this);
  }
}
