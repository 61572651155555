import store from '~/javascripts/store.js';
import { makeAutoObservable } from 'mobx';

export default class {
  annualAmountMultiplier = 9;
  country = 'us';
  customParams = {};
  optimizelyParams = {};
  queryParams = {};

  constructor() {
    makeAutoObservable(this);
  }

  get defaultSubscriptionAmounts() {
    return {
      us: [10, 20, 40, 100],
      uk: [5, 10, 20, 40],
    }[this.country];
  }

  get defaultAnnualSubscriptionAmounts() {
    return this.defaultSubscriptionAmounts.map(
      (n) => n * this.annualAmountMultiplier
    );
  }

  get defaultOneTimeAmounts() {
    return {
      us: [50, 100, 150, 200],
      uk: [25, 50, 75, 100],
    }[this.country];
  }

  get defaultSubscriptionHighlight() {
    return {
      us: 40,
      uk: 20,
    }[this.country];
  }

  get defaultAnnualSubscriptionHighlight() {
    return this.defaultSubscriptionHighlight * this.annualAmountMultiplier;
  }

  get defaultOneTimeHighlight() {
    return {
      us: 100,
      uk: 50,
    }[this.country];
  }

  get displayAmounts() {
    if (store.donateForm.isSubscription) {
      return store.donateForm.isAnnual
        ? this.annualSubscriptionButtonAmounts
        : this.subscriptionButtonAmounts;
    } else {
      return this.oneTimeButtonAmounts;
    }
  }

  get annualSubscriptionButtonAmounts() {
    return {
      values: [
        this.optimizelyParams.annual1 ||
          this.defaultAnnualSubscriptionAmounts[0],
        this.optimizelyParams.annual2 ||
          this.defaultAnnualSubscriptionAmounts[1],
        this.optimizelyParams.annual3 ||
          this.defaultAnnualSubscriptionAmounts[2],
        this.optimizelyParams.annual4 ||
          this.defaultAnnualSubscriptionAmounts[3],
      ],
      highlight:
        this.optimizelyParams.annualHighlight ||
        this.defaultAnnualSubscriptionHighlight,
    };
  }

  get subscriptionButtonAmounts() {
    if (store.donateForm.campaignSlug == 'default') {
      return {
        values: [5, 25, 45, 65],
        highlight: 25,
      };
    } else {
      return {
        values: [
          this.optimizelyParams.monthly1 ||
            this.queryParams.monthly1 ||
            this.customParams.monthly1 ||
            this.defaultSubscriptionAmounts[0],
          this.optimizelyParams.monthly2 ||
            this.queryParams.monthly2 ||
            this.customParams.monthly2 ||
            this.defaultSubscriptionAmounts[1],
          this.optimizelyParams.monthly3 ||
            this.queryParams.monthly3 ||
            this.customParams.monthly3 ||
            this.defaultSubscriptionAmounts[2],
          this.optimizelyParams.monthly4 ||
            this.queryParams.monthly4 ||
            this.customParams.monthly4 ||
            this.defaultSubscriptionAmounts[3],
        ],
        highlight:
          this.optimizelyParams.monthlyHighlight ||
          this.queryParams.monthlyHighlight ||
          this.customParams.monthlyHighlight ||
          this.defaultSubscriptionHighlight,
      };
    }
  }

  get oneTimeButtonAmounts() {
    return {
      values: [
        this.optimizelyParams.oneTime1 ||
          this.queryParams.oneTime1 ||
          this.customParams.oneTime1 ||
          this.defaultOneTimeAmounts[0],
        this.optimizelyParams.oneTime2 ||
          this.queryParams.oneTime2 ||
          this.customParams.oneTime2 ||
          this.defaultOneTimeAmounts[1],
        this.optimizelyParams.oneTime3 ||
          this.queryParams.oneTime3 ||
          this.customParams.oneTime3 ||
          this.defaultOneTimeAmounts[2],
        this.optimizelyParams.oneTime4 ||
          this.queryParams.oneTime4 ||
          this.customParams.oneTime4 ||
          this.defaultOneTimeAmounts[3],
      ],
      highlight:
        this.optimizelyParams.oneTimeHighlight ||
        this.queryParams.oneTimeHighlight ||
        this.customParams.oneTimeHighlight ||
        this.defaultOneTimeHighlight,
    };
  }
}
