export function minimumAmount(options) {
  const defaultSubscriptionMinAmount = 1;
  const subscriptionMinAmount =
    options['customSubscriptionMinAmount'] || defaultSubscriptionMinAmount;

  if (options['isSubscription']) {
    return subscriptionMinAmount;
  } else {
    return options['minAmount'];
  }
}

export function amountInputSubscriptionPeriod(isSubscription, isAnnual) {
  if (isSubscription) {
    return isAnnual ? '/year' : '/month';
  } else {
    return '';
  }
}
