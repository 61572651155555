import { makeAutoObservable } from 'mobx';

export default class {
  accountTypes = [
    { label: 'Individual', value: 'IN', copy: 'an individual' },
    { label: 'Company', value: 'CO', copy: 'a company' },
    { label: 'School', value: 'SC', copy: 'a school' },
    { label: 'Faith-based Group', value: 'FB', copy: 'a faith-based group' },
    { label: 'Foundation', value: 'FD', copy: 'a foundation' },
  ];
  otherAccountTypes = [{ value: 'OT', copy: 'an individual' }];

  accountTypeInfo = () =>
    this.accountTypes
      .concat(this.otherAccountTypes)
      .find((item) => item.value == this.accountType);

  setAccountType = (value) => (this.accountType = value);

  constructor() {
    makeAutoObservable(this);
  }

  get isIndividual() {
    return this.accountType.startsWith('I');
  }
  get accountTypeLabel() {
    return this.isIndividual ? '' : `${this.accountTypeInfo().label} `;
  }
  get accountTypeCopy() {
    return this.accountTypeInfo().copy;
  }
  get accountTypeNamePlaceholder() {
    return this.isIndividual ? 'First ' : `${this.accountTypeInfo().label} `;
  }

  accountType = 'IN';
}
