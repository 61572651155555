import { differenceInYears } from 'date-fns';
import { makeAutoObservable, values } from 'mobx';

export default class {
  isFormSubmitted = false;
  birthdate = {
    birthMonth: '',
    birthDay: '',
    birthYear: '',
  };

  updateBirthdate = (event) => {
    const { target } = event;
    if (target.value != undefined) {
      this.birthdate[target.name] = target.value;
    }
  };

  hasValidBirthdate = () =>
    values(this.birthdate).every(
      (datePart) => datePart != '' && !isNaN(datePart)
    );

  constructor() {
    makeAutoObservable(this);
  }

  get fullBirthdate() {
    if (!this.hasValidBirthdate()) return null;
    return new Date(
      `${this.birthdate.birthMonth}-${this.birthdate.birthDay}-${this.birthdate.birthYear}`
    );
  }

  get calculatedAge() {
    return differenceInYears(new Date(), this.fullBirthdate);
  }
}
