function experiments() {
  if (
    typeof window.optimizely === 'undefined' ||
    typeof window.optimizely.get === 'undefined'
  )
    return;

  const optimizelyData = window.optimizely.get('data');
  const optimizelyState = window.optimizely.get('state');
  const visitorVariants = optimizelyState.getVariationMap();

  const experimentData = {};

  Object.keys(visitorVariants).map((experimentId) => {
    if (!(experimentId in optimizelyData.experiments)) {
      return;
    }
    const experimentName = optimizelyData.experiments[experimentId].name;
    const variantName = visitorVariants[experimentId].name;

    experimentData[`experiment_${experimentId}`] = {
      experiment_id: experimentId,
      experiment_name: experimentName,
      variant_name: variantName,
    };
  });

  return experimentData;
}

export { experiments };
