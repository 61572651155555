import { last } from 'lodash';
import { makeAutoObservable } from 'mobx';

export default class {
  videos = [
    '101308940',
    '105282070',
    '106840826',
    '105692250',
    '107186043',
    '106207888',
    '108854997',
    '111260967',
  ];

  constructor() {
    makeAutoObservable(this);
  }

  get indexOfCurrentVideo() {
    return this.videos.indexOf(this.currentVideo);
  }

  currentVideo = this.videos[0];
  playing = false;

  handleNextVideo = () => {
    if (this.currentVideo === last(this.videos)) {
      this.currentVideo = this.videos[0];
      this.playing = false;
    } else {
      this.currentVideo = this.videos[this.indexOfCurrentVideo + 1];
      this.playing = true;
    }
  };

  setCurrentVideo = (video) => {
    this.currentVideo = video;
    this.playing = true;
  };
}
