export function animationDuration(length) {
  return railsEnv === 'test' ? 1 : length;
}

export function pauseForSpecs(functionToPause) {
  if (!window.pausePauseableJavascripts) return functionToPause();

  console.log('Temporarily pausing pausable Javascript.');

  document.addEventListener('ResumePausedJavascripts', () => {
    console.log('Resuming pausable Javascript.');
    functionToPause();
  });
}
