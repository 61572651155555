import { addMonths, setDate } from 'date-fns';
import { makeAutoObservable } from 'mobx';

export default class {
  nextPaymentDatetime;
  last4 = '';
  lastPaymentDay;
  plaidAccountId = '';
  plaidToken = '';

  setNextPaymentDatetimeFrom = (day) => {
    const currentDay = new Date().getDate();

    if (
      day < currentDay ||
      (this.lastPaymentDay && this.lastPaymentDay <= currentDay)
    ) {
      this.nextPaymentDatetime = setDate(addMonths(new Date(), 1), day);
    } else if (day >= currentDay) {
      this.nextPaymentDatetime = setDate(new Date(), day);
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}
