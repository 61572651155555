import { makeAutoObservable } from 'mobx';

export default class {
  banner = true;
  content = {};
  customText = '';
  designTemplates = [];
  imageBeingGenerated = false;
  imageId = 0;
  loading = '';
  templateId = 0;
  themeId = '';

  constructor() {
    makeAutoObservable(this);
  }

  get toParam() {
    return {
      template_id: this.templateId,
      image_id: this.imageId,
      banner: this.banner,
      customText: this.customText,
      theme_id: this.themeId,
      imageBeingGenerated: this.imageBeingGenerated,
    };
  }

  get selectedTemplate() {
    return this.designTemplates[this.templateId];
  }

  get assetAspectRatio() {
    return (
      this.selectedTemplate.generated_asset_height /
      this.selectedTemplate.generated_asset_width
    );
  }

  get previewScale() {
    return Math.min(($('body').width() - 40) / 420, 1);
  }

  get selectedTemplateNameHandlized() {
    return this.designTemplates[this.templateId].name
      .toLowerCase()
      .replace(/\s+/g, '-');
  }
}
